import { PageProps } from 'gatsby';
import React from 'react';
import { ContentPageLayout } from '../components/org.content-page-layout/content-page-layout.component';

const ProductPage = (props: PageProps) => {
  const data = (props.pageContext as any).markDownRemark;
  const metadata = data.frontmatter;
  return (
    <>
      <ContentPageLayout
        title={metadata.title}
        gatsbyImageData={
          metadata.productHeroImage.childImageSharp.gatsbyImageData
        }
        html={data.html}
      />
    </>
  );
};

export default ProductPage;
